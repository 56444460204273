<template>

<div class="account">

	<com-title text="My account" />

	<com-item route="Profile" text="Profile" icon="profile" />
	<com-item route="Purchases" text="Purchases" icon="purchases" v-if="isApp" />

	<com-item route="Logout" text="Logout" icon="logout" />

	<com-divide :fill="true" />

	<app-logo class="account-logo" />

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./common/Title'),
		'com-item': () => import('./common/Item'),
		'com-divide': () => import('./common/Divide')
	},

	computed: {

		isApp: function() {

			return process.env.VUE_APP_TYPE === 'app'
				
		}

	}

}

</script>

<style scoped>

.account {
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	border-top: 1px solid #2f3a4a;
	padding-bottom: 10px;
}

.account-logo {
	margin: 0px auto;
}

</style>
